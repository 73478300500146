<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          Current CVSS Score Distribution For All Vulnerabilities
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3" dense>
          <v-col cols="12" sm="8" md="6" lg="5">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>CVSS Score</th>
                    <th>Number Of Vulnerabilities</th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in scores" :key="item.score">
                    <td class="text-center">{{ item.score }}</td>
                    <td class="text-right">{{ item.noVulnerabilities }}</td>
                    <td class="text-right">{{ item.percentage }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-sheet height="400px">
              <chart-bar :chart-data="datacollection" :options="options" />
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import ChartBar from "../charts/Bar"
export default {
  name: "DashboardDashboard",
  components: { ChartBar },
  data() {
    return {
      scores: [],
      datacollection: {},
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {},
  created() {
    this.getRecords()
  },
  methods: {
    getRecords() {
      axios.get("cve_scores_top").then(response => {
        this.$log("products list ", response)
        this.scores = response.data
        //this.multipleBar.data.series=[]
        const arr = []
        this.scores.map(itm => {
          if (itm.score == 9) {
            arr.push(itm.noVulnerabilities + this.scores[10].noVulnerabilities)
          } else if (itm.score < 10) {
            arr.push(itm.noVulnerabilities)
          }
        })
        this.datacollection = {
          labels: ["0-1", "1-2", "2-3", "3-4", "4-5", "5-6", "6-7", "7-8", "8-9", "9-10"],
          datasets: [
            {
              label: "Vulnerabilities",
              backgroundColor: "#3F51B5",
              data: arr
            }
          ]
        }
      })
    }
  }
}
</script>
<style scoped>
td:first-child,
th:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
td {
  text-align: right !important;
}
td,
th {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
th {
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}
table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>
